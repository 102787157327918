import React, { FC, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import { mobileMQ, isMobile } from '@config/mediaQueries';
import { ResponsiveImage } from './ResponsiveImage';

const Container = styled.div<{ visible: boolean }>(p =>
  adaptForLargeScreen({
    marginBottom: 85,
    visibility: p.visible ? 'visible' : 'hidden',
    height: 561,
    overflow: 'hidden',

    [mobileMQ]: {
      marginBottom: 50,
      height: 176,
    },
  }),
);

const ImageContainer = styled.div<{ dragging: boolean }>(p =>
  adaptForLargeScreen({
    display: 'block',
    paddingRight: 54,
    marginLeft: -135,
    width: 1120,
    height: 613,
    cursor: p.dragging ? 'grabbing' : 'grab',

    ':focus': {
      outline: 'none',
    },

    [mobileMQ]: {
      width: 339,
      height: 186,
      paddingRight: 20,
      marginLeft: -70,
    },
  }),
);

const Image = styled(ResponsiveImage)(
  adaptForLargeScreen({
    width: '100%',
  }),
);

type Props = { images: CmsImage[] };

export const AboutCarousel: FC<Props> = props => {
  const [ready, setReady] = useState(false);
  const [dragging, setDragging] = useState(false);

  const handleMouseDown = () => {
    setDragging(true);

    const handleMouseUp = () => {
      setDragging(false);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    window.addEventListener('mouseup', handleMouseUp);
  };

  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile() ? 1 : 2,
    slidesToScroll: 1,
    centerMode: true,
    onInit: () => setTimeout(() => setReady(true), 500),
  };

  return (
    <Container visible={ready}>
      <Slider {...settings}>
        {props.images.map((img, i) => (
          <ImageContainer
            dragging={dragging}
            key={i}
            onMouseDown={() => handleMouseDown()}
          >
            <Image image={img} />
          </ImageContainer>
        ))}
      </Slider>
    </Container>
  );
};
