import React, { Fragment } from 'react';

import { Meta } from '@components/Meta';
import { About } from '@components/About';
import { Footer } from '@components/Footer';
import { Layout } from '@components/Layout';

export type AboutProps = {
  siteText: SiteText[];
  siteImages: SiteImages[];
} & BasePageProps;

type Props = { pageContext: AboutProps };

export default ({
  pageContext: { siteText, siteImages, homepageFonts },
}: Props) => (
  <Fragment>
    <Meta title="About" />

    <Layout homepageFonts={homepageFonts}>
      <About siteText={siteText} siteImages={siteImages} />
    </Layout>

    <Footer />
  </Fragment>
);
