import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import {
  BODY_FONT_LARGE,
  PAGE_MARGIN_TOP_LARGE,
  BODY_LETTER_SPACING_LARGE,
  INNER_MARGIN_LEFT_MOBILE,
  INNER_MARGIN_RIGHT_MOBILE,
} from '@config/layout';
import { getSiteTextFactory } from '@utils/siteText';
import { StyledMarkdown } from './StyledMarkdown';
import UIStore from '@stores/UIStore';
import Helmet from 'react-helmet';
import { getSiteImagesFactory } from '@utils/siteImages';
import { AboutCarousel } from './AboutCarousel';
import { mobileMQ } from '@config/mediaQueries';
import { AboutProps } from 'src/templates/About';

const Container = styled.div(
  adaptForLargeScreen({
    marginTop: PAGE_MARGIN_TOP_LARGE,
    [mobileMQ]: { marginTop: 119, marginBottom: 120 },
  }),
);

const Text = styled.div<{ large?: boolean }>(p =>
  adaptForLargeScreen({
    fontSize: BODY_FONT_LARGE,
    marginLeft: 663,
    marginRight: 193,
    marginBottom: 79,
    letterSpacing: BODY_LETTER_SPACING_LARGE,

    [mobileMQ]: {
      marginLeft: INNER_MARGIN_LEFT_MOBILE,
      marginRight: INNER_MARGIN_RIGHT_MOBILE,
      fontSize: p.large ? 25 : 20,
      lineHeight: p.large ? 1.25 : 1.5,
      marginBottom: 50,
    },
  }),
);

export const About = (props: AboutProps) => {
  const { siteText, siteImages } = props;
  const getSiteText = getSiteTextFactory(siteText, 'About');
  const getSiteImages = getSiteImagesFactory(siteImages, 'About');

  useLayoutEffect(() => UIStore.setupPage('#A3A1FF', '#000'), []);

  return (
    <Container>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <style type="text/css">{`
        .slick-list{ margin-left: -17vw; }
        `}</style>
      </Helmet>
      <Text large>
        <StyledMarkdown source={getSiteText('Top Text')} />
      </Text>
      <AboutCarousel images={getSiteImages('Top Images')} />
      <Text>
        <StyledMarkdown source={getSiteText('Middle Text')} />
      </Text>
      <AboutCarousel images={getSiteImages('Bottom Images')} />
      <Text>
        <StyledMarkdown source={getSiteText('Bottom Text')} />
      </Text>
    </Container>
  );
};
